// app/javascript/controllers/payment_form_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["amount", "submit", "error", "allocation"];

  connect = () => {
    this.validateAmount();
  };

  validateAmount = () => {
    const paymentAmount = parseFloat(this.amountTarget.value) || 0;
    const remainingBalance = parseFloat(this.amountTarget.dataset.remaining) || 0;

    let totalAllocated = 0;
    this.allocationTargets.forEach((input) => {
      totalAllocated += parseFloat(input.value) || 0;
    });

    if (paymentAmount > remainingBalance) {
      this.submitTarget.disabled = true;
      this.errorTarget.textContent = "Payment amount exceeds the remaining balance.";
      this.errorTarget.hidden = false;
      return;
    }

    if (totalAllocated > paymentAmount) {
      this.submitTarget.disabled = true;
      this.errorTarget.textContent = "Allocated amounts exceed the payment amount.";
      this.errorTarget.hidden = false;
      return;
    }

    this.submitTarget.disabled = false;
    this.errorTarget.hidden = true;
  };
}
