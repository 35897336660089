import { Turbo } from "@hotwired/turbo-rails";

// Customizable command palette for advanced users
// Opens with cmd+k or ctrl+k by default
// https://github.com/excid3/ninja-keys

import { Controller } from "@hotwired/stimulus";
import "@gorails/ninja-keys";
import Rails from "@rails/ujs";

const rootData = [
  {
    id: "CreatePatient",
    title: "Create Patient",
    hotkey: "ctrl+N",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" /></svg>',
    handler: () => {
      Turbo.visit("/patients/new");
    },
  },
];

export default class extends Controller {
  static targets = ["ninjakeys"];
  connect() {
    this.ninjakeysTarget.data = rootData;
    this.ninjakeysTarget.placeholder = "Search for a patient...";

    this.element.addEventListener("change", (event) => {
      // detail = {search: 'your search query', actions: Array<NinjaAction>}
      if (event.detail?.search) {
        this.searchPatients(event.detail.search);
      } else {
        this.ninjakeysTarget.data = rootData;
      }
    });
  }
  openPalette() {
    this.ninjakeysTarget.open();
  }
  searchPatients(query) {
    if (!query) return;

    Rails.ajax({
      type: "GET",
      url: `/patients/search?query=${encodeURIComponent(query)}`,
      success: (response) => {
        this.updateCommands(response, query);
      },
      error: (error) => {
        console.error("Search request failed", error);
        this.updateCommands(rootData, query);
      },
    });
  }

  updateCommands(patients, query) {
    const patientCommands = patients.map((patient) => ({
      id: `patient-${patient.id}`,
      title: `${patient.firstName} ${patient.lastName} (${patient.email})`,
      handler: () => {
        Turbo.visit(`/patients/${patient.id}`);
      },
    }));

    this.ninjakeysTarget.data = [
      ...this.ninjakeysTarget.data
        .filter((cmd) => !cmd.id.startsWith("patient-"))
        .map((cmd) => {
          if (cmd.id.startsWith("CreatePatient") && patientCommands.length === 0) {
            return {
              ...cmd,
              keywords: `${query}`,
            };
          } else {
            return {
              ...cmd,
              keywords: "",
            };
          }
        }),
      ...patientCommands,
    ];
  }
}
