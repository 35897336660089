import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "panel", "selectedIndexInput"];
  static values = {
    id: String,
    activeClass: String,
    inactiveClass: String,
    initialIndex: Number,
  };

  connect = () => {
    this.activeClasses = this.activeClassValue.split(" ");
    this.inactiveClasses = this.inactiveClassValue.split(" ");
    this.showTab(this.initialIndexValue);
  };

  switchTab = (event) => {
    this.showTab(parseInt(event.params["index"]));
  };

  showTab = (index) => {
    this.tabTargets.forEach((tab, i) => {
      if (i === index) {
        tab.classList.remove(...this.inactiveClasses);
        tab.classList.add(...this.activeClasses);
      } else {
        tab.classList.add(...this.inactiveClasses);
        tab.classList.remove(...this.activeClasses);
      }
    });

    this.panelTargets.forEach((panel, i) => {
      if (i === index) {
        panel.classList.remove("hidden");
      } else {
        panel.classList.add("hidden");
      }
    });

    if (this.hasSelectedIndexInputTarget) {
      this.selectedIndexInputTarget.value = index;
    }
  };
}
