import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "button"];

  toggle() {
    this.checkboxTarget.checked = !this.checkboxTarget.checked;
    this.buttonTarget.setAttribute("aria-checked", this.checkboxTarget.checked);

    // Update visual state
    if (this.checkboxTarget.checked) {
      this.buttonTarget.classList.remove("bg-gray-200");
      this.buttonTarget.classList.add("bg-indigo-600");
      this.buttonTarget.querySelector("span:not(.sr-only)").classList.remove("translate-x-0");
      this.buttonTarget.querySelector("span:not(.sr-only)").classList.add("translate-x-5");
    } else {
      this.buttonTarget.classList.add("bg-gray-200");
      this.buttonTarget.classList.remove("bg-indigo-600");
      this.buttonTarget.querySelector("span:not(.sr-only)").classList.add("translate-x-0");
      this.buttonTarget.querySelector("span:not(.sr-only)").classList.remove("translate-x-5");
    }
  }
}
