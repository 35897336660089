import { Controller } from "@hotwired/stimulus";
import { computePosition, offset, flip, shift } from "@floating-ui/dom";

export default class extends Controller {
  static targets = ["trigger", "popover", "search", "list", "hiddenInput"];

  connect = () => {
    this.isOpen = false;
    this.filteredOptions = [...this.listTarget.children];
    document.addEventListener("click", this.handleClickOutside);
    document.addEventListener("keydown", this.handleEscape);
  };

  disconnect = () => {
    document.removeEventListener("click", this.handleClickOutside);
    document.removeEventListener("keydown", this.handleEscape);
  };

  togglePopover = () => {
    this.isOpen ? this.closePopover() : this.openPopover();
  };

  openPopover = () => {
    this.isOpen = true;
    this.popoverTarget.classList.remove("hidden");
    computePosition(this.triggerTarget, this.popoverTarget, {
      placement: "right-start",
      middleware: [offset(8), flip(), shift({ padding: 8 })],
    }).then(({ x, y }) => {
      Object.assign(this.popoverTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
    this.searchTarget.focus();
  };

  closePopover = () => {
    this.isOpen = false;
    this.popoverTarget.classList.add("hidden");
    this.searchTarget.value = "";
    this.filterOptions("");
  };

  filterOptions = (value) => {
    this.filteredOptions.forEach((option) => {
      const text = option.textContent.toLowerCase();
      const match = text.includes(value.toLowerCase());
      option.style.display = match ? "block" : "none";
    });
  };

  search = (event) => {
    this.filterOptions(event.target.value);
  };

  select = (event) => {
    const selectedOption = event.params.id;
    this.hiddenInputTarget.value = selectedOption;
    this.closePopover();
  };

  handleClickOutside = (event) => {
    if (
      this.isOpen &&
      !this.popoverTarget.contains(event.target) &&
      !this.triggerTarget.contains(event.target)
    ) {
      this.closePopover();
    }
  };

  // TODO: proper keyboard navigation and a11y
  handleEscape = (event) => {
    if (event.key === "Escape" && this.isOpen) {
      this.closePopover();
    }
  };
}
