import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "results", "patientId"];

  connect() {
    this.inputTarget.addEventListener("input", this.search.bind(this));
  }

  search() {
    const query = this.inputTarget.value;

    if (query.length < 2) {
      this.resultsTarget.innerHTML = "";
      this.resultsTarget.classList.add("hidden");
      return;
    }

    fetch(`/patients/search?query=${encodeURIComponent(query)}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.resultsTarget.innerHTML = this.buildResultsHtml(data);
          this.resultsTarget.classList.remove("hidden");
        } else {
          this.resultsTarget.innerHTML = "";
          this.resultsTarget.classList.add("hidden");
        }
      });
  }

  buildResultsHtml(patients) {
    return patients
      .map(
        (patient) => `
          <div class="patient-result cursor-pointer hover:bg-gray-100 p-2" data-action="click->patient-search-select#selectPatient" data-patient-id="${patient.id}">
            <div class="font-medium text-gray-900">${patient.firstName} ${patient.lastName}</div>

            <div class="text-sm text-gray-500">${patient.email || "No email"}</div>
          </div>
        `,
      )
      .join("");
  }

  selectPatient(event) {
    const patientId = event.currentTarget.dataset.patientId;
    const patientName = event.currentTarget.querySelector(".font-medium").textContent.trim();

    this.inputTarget.value = patientName;
    this.patientIdTarget.value = patientId;
    this.resultsTarget.innerHTML = "";
  }
}
