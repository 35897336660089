import { Controller } from "@hotwired/stimulus";
import Pikaday from "pikaday";
import "./datepicker.css";

export default class extends Controller {
  static targets = ["input"];
  static values = { id: String };

  connect() {
    this.picker = new Pikaday({
      field: this.inputTarget,
      trigger: this.element,
      onSelect: (date) => {
        // in timeout so event is dispatched close to when datepicker closes
        setTimeout(() => {
          this.dispatch("date-selected", {
            prefix: `${this.identifier}/${this.idValue}`,
            detail: { date },
          });
        }, 100);
      },
      position: "bottom center",
      theme: "datepicker",
    });
    this.picker.setDate(new Date());
  }

  disconnect() {
    if (this.picker) {
      this.picker.destroy();
    }
  }

  goToDate(event) {
    const newDate = event.detail.date;
    const currentDate = this.picker.getDate();
    if (newDate.toDateString() !== currentDate?.toDateString()) {
      this.picker.setDate(newDate);
    }
  }
}
