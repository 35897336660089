import { Controller } from "@hotwired/stimulus";
import { Calendar } from "@fullcalendar/core";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import { Turbo } from "@hotwired/turbo-rails";
import { patch } from "@rails/request.js";

const STATUS_COLORS = {
  planned: "bg-brand-orange",
  scheduled: "bg-brand-cyan",
  checked_in: "bg-brand-green",
  completed: "bg-brand-orange",
  cancelled: "bg-brand-pink",
};

// Connects to data-controller="calendar"
export default class extends Controller {
  static targets = ["calendar", "dateButton", "dateDisplay"];
  static values = { locationId: Number };

  connect() {
    this.initializeCalendar();
  }

  initializeCalendar() {
    this.calendar = new Calendar(this.calendarTarget, {
      schedulerLicenseKey: "0300162430-fcs-1709102530",
      timeZone: "local",
      plugins: [resourceTimeGridPlugin, interactionPlugin],
      initialView: "resourceTimeGridDay",
      editable: true,
      eventDrop: (info) => this.handleEventDrop(info),
      eventResize: (info) => this.handleEventResize(info),
      snapDuration: { minutes: 10 }, // TODO: should be the time "unit" setting
      eventOverlap: false,
      nowIndicator: true,
      scrollTime: "08:00:00", // where the calendar will start on each day
      resources: `/settings/locations/${this.locationIdValue}/operatories.json`,
      events: `/locations/${this.locationIdValue}/appointments.json`,
      headerToolbar: false, // We'll create a custom header
      allDaySlot: false,
      slotDuration: "00:20:00",
      slotLabelInterval: "01:00",
      slotMinTime: "08:00:00",
      slotMaxTime: "24:00:00",
      expandRows: true,
      height: "auto",
      stickyHeaderDates: "true",
      slotLabelFormat: {
        hour: "numeric",
        minute: "2-digit",
        omitZeroMinute: true,
        meridiem: "short",
      },
      dayHeaderFormat: { weekday: "short", day: "numeric" },
      eventClick: (info) => {
        // Check if the click is on an actual event
        if (!info.event) return;
        // TODO: if its not, close the appointment preview modal

        info.jsEvent.preventDefault();

        const dialogContainer = document.getElementById("calendar_appointment_preview_frame");

        const positionDialog = (initialRender) => {
          if (initialRender) {
            // do initial render while hidden so div can get sized
            dialogContainer.style.visibility = "visible";
            dialogContainer.classList.remove("transition-opacity");
            dialogContainer.classList.add("opacity-0");

            // TODO: turn into helper function for future modals
            function frameLoadHandler(event) {
              if (event.target.id === "calendar_appointment_preview_frame") {
                positionDialog();
                document.removeEventListener("turbo:frame-load", frameLoadHandler);
              }
            }
            document.addEventListener("turbo:frame-load", frameLoadHandler);
            return;
          }

          // Calculate available space on each side
          const calendarRect = this.element.getBoundingClientRect();
          const eventRect = info.el.getBoundingClientRect();
          const spaceRight = calendarRect.right - eventRect.right;
          const spaceLeft = eventRect.left;
          const spaceBottom = calendarRect.bottom - eventRect.bottom;
          const viewportHeight = window.innerHeight;
          const dialogHeight = dialogContainer.offsetHeight;
          const dialogWidth = dialogContainer.offsetWidth;

          let left, top;
          if (spaceRight >= dialogWidth) {
            left = eventRect.right + 10;
            top = Math.min(eventRect.top, viewportHeight - dialogHeight);
          } else if (spaceLeft >= dialogWidth) {
            left = eventRect.left - dialogWidth - 10;
            top = Math.min(eventRect.top, viewportHeight - dialogHeight);
          } else {
            left = Math.max(0, (eventRect.left + eventRect.right - dialogWidth) / 2);
            if (spaceBottom >= dialogHeight) {
              top = eventRect.bottom + 10;
            } else {
              top = Math.max(0, eventRect.top - dialogHeight - 10);
            }
          }

          // Set the position
          dialogContainer.style.left = `${left}px`;
          dialogContainer.style.top = `${top}px`;
          dialogContainer.classList.add("transition-opacity");
          dialogContainer.classList.remove("opacity-0");
        };

        Turbo.visit(info.event.url, { frame: "calendar_appointment_preview_frame" });
        const patientId = info.event.extendedProps.patientId;
        Turbo.visit(`/patients/${patientId}/panel`, { frame: "panel_frame" });
        positionDialog(true);
      },
      eventContent: (arg) => {
        const eventDuration = arg.event.end.getTime() - arg.event.start.getTime();
        const isShortEvent = eventDuration <= 30 * 60 * 1000; // 30 minutes in milliseconds
        const bgColorClass = STATUS_COLORS[arg.event.extendedProps.status] || "";

        const commonClasses =
          "transition duration-300 ease-in-out hover:shadow-md rounded-xl flex overflow-hidden h-full w-full";

        if (isShortEvent) {
          return {
            html: `
              <div class="px-2 py-1 ${bgColorClass} items-center ${commonClasses}">
                <div class="flex-grow min-w-0">
                  <div class="text-typography-primary text-xs font-semibold truncate">${arg.event.extendedProps.patientName}</div>
                </div>
                <div class="flex items-center space-x-1 flex-shrink-0">
                  <div class="text-typography-tertiary text-xs">${arg.event.extendedProps.procedureCode}</div>
                  ${
                    arg.event.extendedProps.additionalProcedures > 0
                      ? `
                    <div class="px-1 bg-white/50 rounded text-typography-tertiary text-xs">
                      +${arg.event.extendedProps.additionalProcedures}
                    </div>
                  `
                      : ""
                  }
                </div>
              </div>
            `,
          };
        } else {
          return {
            html: `
              <div class="p-2 ${bgColorClass} flex-col justify-between ${commonClasses}">
                <div class="px-4 py-1.5">
                  <div class="flex justify-between items-start">
                    <div class="text-typography-primary text-sm font-semibold truncate mr-2">${arg.event.extendedProps.patientName}</div>
                    <img class="w-5 h-5 rounded-full flex-shrink-0" src="${arg.event.extendedProps.patientAvatarUrl}" alt="Patient avatar" />
                  </div>
                  <div class="flex justify-between items-end mt-1">
                    <div class="text-typography-secondary text-xs">${arg.timeText}</div>
                    <div class="flex items-center space-x-1">
                      <div class="text-typography-secondary text-xs">${arg.event.extendedProps.procedureCode}</div>
                      ${
                        arg.event.extendedProps.additionalProcedures > 0
                          ? `
                        <div class="px-1 bg-white/50 rounded text-typography-secondary text-xs">
                          +${arg.event.extendedProps.additionalProcedures}
                        </div>
                      `
                          : ""
                      }
                    </div>
                  </div>
                </div>
              </div>
            `,
          };
        }
      },
      eventDataTransform: (appointment) => {
        const color = STATUS_COLORS[appointment.status] || "brand-light";

        return {
          id: appointment.id,
          title: appointment.patientName,
          start: appointment.start,
          end: appointment.end,
          allDay: appointment.allDay,
          resourceId: appointment.resourceId,
          url: appointment.previewUrl,
          backgroundColor: color,
          extendedProps: {
            patientId: appointment.patientId,
            patientName: appointment.patientName,
            patientAvatarUrl: appointment.patientAvatarUrl || "https://via.placeholder.com/20x20",
            status: appointment.status,
            procedureCode: appointment.procedureCode || "N/A",
            additionalProcedures: appointment.additionalProcedures || 0,
          },
        };
      },
      datesSet: () => {
        this.updateDateDisplay();
        this.closeAppointmentPreview();
        this.dispatch("date-changed", {
          detail: { date: this.calendar.getDate() },
        });
      },
    });

    this.calendar.render();
  }

  handleEventDrop = (info) => {
    this.updateAppointment(info.event);
  };

  handleEventResize = (info) => {
    this.updateAppointment(info.event);
  };

  updateAppointment = (event) => {
    const appointmentId = event.id;
    const resourceId = event.getResources()[0].id;

    patch(`/appointments/${appointmentId}/reschedule`, {
      body: {
        appointment: {
          start_time: event.startStr,
          duration_minutes: Math.round((event.end - event.start) / (1000 * 60)),
          operatory_id: resourceId,
        },
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update appointment");
        }
        // TODO: visual feedback
      })
      .catch(() => {
        // Revert the event to its original position
        this.calendar.refetchEvents();
      });
  };

  goToDate(event) {
    const newDate = event.detail.date;
    const currentDate = this.calendar.getDate();
    if (newDate.toDateString() !== currentDate?.toDateString()) {
      this.calendar.gotoDate(newDate);
    }
  }

  updateDateDisplay() {
    const currentDate = this.calendar.getDate();
    let formattedDate;
    if (currentDate.toDateString() === new Date().toDateString()) {
      formattedDate = `Today, ${currentDate.toLocaleString("default", { month: "short", day: "numeric" })}`;
    } else {
      formattedDate = currentDate.toLocaleString("default", {
        weekday: "long",
        month: "short",
        day: "numeric",
      });
    }
    this.dateDisplayTarget.textContent = formattedDate;
  }

  eventData(info) {
    return {
      "appointment[start_time]": info.event.start,
      "appointment[end_time]": info.event.end,
      "appointment[operatory_id]": info.event.getResources()[0].id,
      "appointment[status]": info.event.status,
    };
  }

  goToToday() {
    this.calendar.today();
  }

  closeAppointmentPreview() {
    // TODO: figure out proper way to clear
    const previewDialog = document.getElementById("calendar_appointment_preview_frame");
    if (previewDialog) {
      previewDialog.innerHTML = "";
    }
  }

  refetchEvents() {
    this.calendar.refetchEvents();
  }

  next() {
    this.calendar.next();
  }

  prev() {
    this.calendar.prev();
  }
}
